import React from "react"
import { Widget } from "@typeform/embed-react"

import Layout from "../components/layout"

const SurveyPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Widget
        id="QN0jCUm3"
        style={{ width: "100%", height: "800px" }}
        className="my-form"
      />
    </Layout>
  )
}

export default SurveyPage
